$allowed-radii: (
  '0': '0',
  '20px': '20px',
  '30px': '30px',
  '40px': '40px',
  '2em': '2em',
  '3em': '3em',
  '4em': '4em',
  '5em': '5em',
  '6em': '6em',
  '7em': '7em',
  '8em': '8em'
);

$tile-and-tout-border-radii: map-merge(
  (
    '': '0'
  ),
  $allowed-radii
);
$popup-border-radii: map-merge(
  (
    '': '0'
  ),
  $allowed-radii
);

.adv_cal_cap {
  .adv-cal-cap-container {
    .adv-cal-cap-wrapper {
      @include swap_direction(margin, 10px auto);
      max-width: 1221px;
      min-width: 1221px;
      @media #{$large-down} {
        min-width: 100%;
      }
      .cards {
        @media #{$medium-up} {
          @include swap_direction(margin, 15px);
        }
        @media #{$large-down} and (orientation: landscape) {
          width: 70%;
          margin-#{$ldirection}: 16%;
        }
        @media #{$device-ipad_mini} and (orientation: landscape) {
          width: 80%;
          margin-#{$ldirection}: 10%;
        }
        @media #{$device-medium-only} and (orientation: landscape) {
          width: 70%;
          margin-#{$ldirection}: 16%;
        }
        @media #{$device-iphonex-landscape-only} and (orientation: landscape) {
          width: 70%;
          margin-#{$ldirection}: 16%;
        }
        .card {
          @each $suffix, $prefix in $tile-and-tout-border-radii {
            &--radius#{$suffix} {
              border-radius: #{$prefix};
            }
          }
          background-repeat: no-repeat;
          margin: 7px;
          max-width: inherit;
          overflow: visible;
          box-shadow: none;
          &.active,
          &.future {
            box-shadow: none;
          }
          &.active:hover,
          &.future:hover {
            box-shadow: none;
          }
          &.tile {
            height: 177px;
            background-size: 100% 100%;
            width: 29%;
            @media #{$iphone6-landscape} {
              height: 215px;
            }
            @media #{$device-ipad_mini} {
              height: 215px;
              width: 130px;
            }
            @media #{$device-medium-only} and (orientation: landscape) {
              height: 250px;
            }
            @media #{$device-iphonex-landscape-only} and (orientation: landscape) {
              height: 250px;
            }
            @media #{$xlarge-up} {
              width: 200px;
              height: 330px;
              background-size: 100% 100%;
              box-shadow: none;
            }
          }
          &.tout {
            border-style: none;
          }
          @media #{$small-only} {
            margin: 3px;
          }
        }
        .tout {
          @each $suffix, $prefix in $tile-and-tout-border-radii {
            &--radius#{$suffix} {
              border-radius: #{$prefix};
            }
            &__img {
              &--radius#{$suffix} {
                border-radius: #{$prefix};
              }
            }
          }
          background-size: 100% 100%;
          height: 177px;
          width: 29%;
          @media #{$iphone6-landscape} {
            height: 215px;
          }
          @media #{$device-ipad_mini} {
            height: 215px;
            width: 130px;
          }
          @media #{$device-medium-only} and (orientation: landscape) {
            height: 250px;
          }
          @media #{$device-iphonex-landscape-only} and (orientation: landscape) {
            height: 250px;
          }
          @media #{$xlarge-up} {
            width: 200px;
            height: 330px;
          }
          &__background {
            img {
              height: 177px;
              width: 100%;
              @media #{$large-up} {
                height: 330px;
              }
              @media #{$iphone6-landscape} {
                height: 215px;
              }
              @media #{$device-ipad_mini} {
                height: 215px;
              }
              @media #{$device-medium-only} and (orientation: landscape) {
                height: 250px;
              }
              @media #{$device-iphonex-landscape-only} and (orientation: landscape) {
                height: 250px;
              }
            }
          }
        }
      }
    }
    .adv-cal-cap-popup-background {
      position: fixed;
      top: 0;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: none;
      z-index: 999;
      background: $color-black;
      opacity: 0.4;
    }
    .adv-cal-cap-popup {
      position: absolute;
      .popup-content-wrapper {
        @each $suffix, $prefix in $popup-border-radii {
          &--radius#{$suffix} {
            border-radius: #{$prefix};
          }
        }
        border: 2px solid $color-black;
        box-shadow: none;
        overflow: hidden;
        .messages {
          height: 100%;
          border: none;
          background: $color-strong-red;
        }
        .popup-close {
          @media #{$small-only} {
            #{$rdirection}: 20px;
          }
          top: 15px;
          #{$rdirection}: 30px;
          background: none;
          &:before,
          &:after {
            @media #{$small-only} {
              height: 30px;
            }
            background-color: $color-dark-gray;
            height: 40px;
          }
        }
        .offer-details {
          @each $suffix, $prefix in $popup-border-radii {
            &--radius#{$suffix} {
              border-radius: #{$prefix};
            }
          }
          &__img {
            margin-top: 0;
            text-align: center;
            width: 100%;
            overflow: hidden;
            @each $suffix, $prefix in $popup-border-radii {
              &--radius#{$suffix} {
                border-top-#{$ldirection}-radius: #{$prefix};
                border-top-#{$rdirection}-radius: #{$prefix};
              }
            }
            display: table;
            img {
              width: 100%;
            }
          }
          &__content {
            &__align {
              text-align: #{$ldirection};
              &--center {
                text-align: center;
              }
              &--right {
                text-align: #{$rdirection};
              }
              &--left {
                text-align: #{$ldirection};
              }
            }
            @include swap_direction(margin, 0 30px);
            width: calc(100% - (2 * 30px));
            p {
              @include swap_direction(margin, 10px 0);
            }
          }
          &__controls {
            .btn-wrapper {
              display: flex;
              flex-direction: row;
              &__position {
                justify-content: #{$ldirection};
                margin-#{$ldirection}: 30px;
                &--center {
                  justify-content: center;
                }
                &--right {
                  justify-content: #{$rdirection};
                  margin-#{$rdirection}: 30px;
                }
                &--left {
                  justify-content: #{$ldirection};
                  margin-#{$ldirection}: 30px;
                }
              }
              a {
                border: 1px solid $color-black;
                height: 38px;
                margin-top: 0;
                margin-bottom: 20px;
                text-align: center;
                font-size: 12px;
                font-weight: bold;
              }
            }
          }
        }
        .offer-details {
          height: 100%;
        }
        .offer-details__panel {
          max-width: 100%;
          max-height: 100%;
        }
        .offer-details__controls {
          max-width: 100%;
        }
      }
    }
  }
  .fixed-header-check {
    display: none;
    visibility: visible;
  }
}
